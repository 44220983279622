import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yesNoOptions } from "../../../../../utils/yesNoOptions";
import {
  getSafetyAndSecurityDetails,
  saveSafetyAndSecurityDetails,
  setLoader,
} from "../../../../../store/thunk/site";
import { isManagerAdminLogin } from "../../../../../utils/isManagerAdminLogin";

const SafetySecurity = ({
  updateSite,
  saveSafetyAndSecurityDetails,
  getSafetyAndSecurityDetails,
  setLoader,
  loggedInUserData,
}) => {
  const isViewMode = updateSite?.isViewMode;
  const {
    register,
    handleSubmit,
    setValue,
  } = useForm({});
  useEffect(() => {
    getSafetyAndSecurityDetails(updateSite?.siteId, setValue);
  }, []);
  const saveSafetyAndSecurity = (data) => {
    setLoader(true);
    saveSafetyAndSecurityDetails(updateSite?.siteId, data);
  };
  return (
    <div className="container">
      <form
        className="d-flex flex-wrap gap-3"
        onSubmit={handleSubmit(saveSafetyAndSecurity)}
      >
        <div>
          <label htmlFor="extFabric" name="extFabric" id="extFabric">
            External Fabric
          </label>
          <div>
            <input
              type="text"
              name="extFabric"
              id="extFabric"
              className="form-control"
              {...register("extFabric")}
              disabled={isViewMode}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="extMetallicFireEscapeStaircases"
            name="extMetallicFireEscapeStaircases"
            id="extMetallicFireEscapeStaircases"
          >
            External Metallic Fire Escape Staircases
          </label>
          <div>
            <input
              type="number"
              name="extMetallicFireEscapeStaircases"
              id="extMetallicFireEscapeStaircases"
              className="form-control"
              {...register("extMetallicFireEscapeStaircases")}
              disabled={isViewMode}
              min="0"
              onChange={(e) => {
                const value = e.target.value;
                if (value < 0) {
                  e.target.value = 0;
                }
                register("extMetallicFireEscapeStaircases").onChange(e);
              }}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="extTimberFireEscapeStaircases"
            name="extTimberFireEscapeStaircases"
            id="extTimberFireEscapeStaircases"
          >
            External Timber Fire Escape Staircases
          </label>
          <div>
            <input
              type="number"
              name="extTimberFireEscapeStaircases"
              id="extTimberFireEscapeStaircases"
              className="form-control"
              {...register("extTimberFireEscapeStaircases")}
              disabled={isViewMode}
              min="0"
              onChange={(e) => {
                const value = e.target.value;
                if (value < 0) {
                  e.target.value = 0;
                }
                register("extTimberFireEscapeStaircases").onChange(e);
              }}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="verticalLadder"
            name="verticalLadder"
            id="verticalLadder"
          >
            Vertical Ladder
          </label>
          <div>
            <input
              type="number"
              name="verticalLadder"
              id="verticalLadder"
              className="form-control"
              {...register("verticalLadder")}
              disabled={isViewMode}
              min="0"
              onChange={(e) => {
                const value = e.target.value;
                if (value < 0) {
                  e.target.value = 0;
                }
                register("verticalLadder").onChange(e);
              }}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="confinedSpaces"
            name="confinedSpaces"
            id="confinedSpaces"
          >
            Confined Spaces
          </label>
          <div>
            <select
              name="confinedSpaces"
              id="confinedSpaces"
              className="form-control form-select"
              {...register("confinedSpaces")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="accessibleUnguardedRoofAreas"
            name="accessibleUnguardedRoofAreas"
            id="accessibleUnguardedRoofAreas"
          >
            Accessible Unguarded Roof Areas
          </label>
          <div>
            <select
              name="accessibleUnguardedRoofAreas"
              id="accessibleUnguardedRoofAreas"
              className="form-control form-select"
              {...register("accessibleUnguardedRoofAreas")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="fragileRoof" name="fragileRoof" id="fragileRoof">
            Fragile Roofs or Surfaces
          </label>
          <div>
            <select
              name="fragileRoof"
              id="fragileRoof"
              className="form-control form-select"
              {...register("fragileRoof")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="lightingConductoreInstalltion"
            name="lightingConductoreInstalltion"
            id="lightingConductoreInstalltion"
          >
            Lighting Conductor Installation
          </label>
          <div>
            <select
              name="lightingConductoreInstalltion"
              id="lightingConductoreInstalltion"
              className="form-control form-select"
              {...register("lightingConductoreInstalltion")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="fireAlarmSystem"
            name="fireAlarmSystem"
            id="fireAlarmSystem"
          >
            Fire Alarm/Detection System
          </label>
          <div>
            <select
              name="fireAlarmSystem"
              id="fireAlarmSystem"
              className="form-control form-select"
              {...register("fireAlarmSystem")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="firePanelLocation"
            name="firePanelLocation"
            id="firePanelLocation"
          >
            Fire Panel Location
          </label>
          <div>
            <input
              type="text"
              name="firePanelLocation"
              id="firePanelLocation"
              className="form-control"
              {...register("firePanelLocation")}
              disabled={isViewMode}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="oilStorageOnSite"
            name="oilStorageOnSite"
            id="oilStorageOnSite"
          >
            Oil/Petrol Storage on Site
          </label>
          <div>
            <select
              name="oilStorageOnSite"
              id="oilStorageOnSite"
              className="form-control form-select"
              {...register("oilStorageOnSite")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="lpgStorageOnSite"
            name="lpgStorageOnSite"
            id="lpgStorageOnSite"
          >
            LPG Storage on Site
          </label>
          <div>
            <select
              name="lpgStorageOnSite"
              id="lpgStorageOnSite"
              className="form-control form-select"
              {...register("lpgStorageOnSite")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="lpgBulkStorageOnSite"
            name="lpgBulkStorageOnSite"
            id="lpgBulkStorageOnSite"
          >
            LPG Bulk Storage on Site
          </label>
          <div>
            <select
              name="lpgBulkStorageOnSite"
              id="lpgBulkStorageOnSite"
              className="form-control form-select"
              {...register("lpgBulkStorageOnSite")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="lpgCylinderStorageOnSite"
            name="lpgCylinderStorageOnSite"
            id="lpgCylinderStorageOnSite"
          >
            LPG Cylinder Storage on Site
          </label>
          <div>
            <select
              name="lpgCylinderStorageOnSite"
              id="lpgCylinderStorageOnSite"
              className="form-control form-select"
              {...register("lpgCylinderStorageOnSite")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="sprinklerSystem"
            name="sprinklerSystem"
            id="sprinklerSystem"
          >
            Sprinkler System
          </label>
          <div>
            <select
              name="sprinklerSystem"
              id="sprinklerSystem"
              className="form-control form-select"
              {...register("sprinklerSystem")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="hoseReels" name="hoseReels" id="hoseReels">
            Hose Reels
          </label>
          <div>
            <select
              name="hoseReels"
              id="hoseReels"
              className="form-control form-select"
              {...register("hoseReels")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="securityGuardEmployed"
            name="securityGuardEmployed"
            id="securityGuardEmployed"
          >
            Are Security Guards Employed
          </label>
          <div>
            <select
              name="securityGuardEmployed"
              id="securityGuardEmployed"
              className="form-control form-select"
              {...register("securityGuardEmployed")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="internalCCTV" name="internalCCTV" id="internalCCTV">
            Internal CCTV
          </label>
          <div>
            <select
              name="internalCCTV"
              id="internalCCTV"
              className="form-control form-select"
              {...register("internalCCTV")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="externalCCTV" name="externalCCTV" id="externalCCTV">
            External CCTV
          </label>
          <div>
            <select
              name="externalCCTV"
              id="externalCCTV"
              className="form-control form-select"
              {...register("externalCCTV")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="automaticBarrier"
            name="automaticBarrier"
            id="automaticBarrier"
          >
            Automatic Barrier
          </label>
          <div>
            <select
              name="automaticBarrier"
              id="automaticBarrier"
              className="form-control form-select"
              {...register("automaticBarrier")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="automaticGatesSliding"
            name="automaticGatesSliding"
            id="automaticGatesSliding"
          >
            Automatic Gates (Sliding)
          </label>
          <div>
            <select
              name="automaticGatesSliding"
              id="automaticGatesSliding"
              className="form-control form-select"
              {...register("automaticGatesSliding")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="automaticGatesHinged"
            name="automaticGatesHinged"
            id="automaticGatesHinged"
          >
            Automatic Gates (Hinged)
          </label>
          <div>
            <select
              name="automaticGatesHinged"
              id="automaticGatesHinged"
              className="form-control form-select"
              {...register("automaticGatesHinged")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="manualSwingGates"
            name="manualSwingGates"
            id="manualSwingGates"
          >
            Manual Swing Gates
          </label>
          <div>
            <select
              name="manualSwingGates"
              id="manualSwingGates"
              className="form-control form-select"
              {...register("manualSwingGates")}
              disabled={isViewMode}
            >
              {yesNoOptions.map((itm) => (
                <option value={itm.value}>{itm.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div
          style={{
            display: (isViewMode || !isManagerAdminLogin(loggedInUserData)) ? "none" : "block",
          }}
        >
          <button className="btn btn-primary float-end m-3" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  updateSite: state.site.updateSite,
  success: state.site.updateSuccess,
  error: state.site.updateError,
  loggedInUserData: state.site.loggedInUserData,
});
export default connect(mapStateToProps, {
  saveSafetyAndSecurityDetails,
  getSafetyAndSecurityDetails,
  setLoader,
})(SafetySecurity);
