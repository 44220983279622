export const SiteArea = [
  "East Midlands",
  "Ireland & Northern Ireland",
  "London & Eastern",
  "North East, Yorkshire & Humberside",
  "North West",
  "Scotland",
  "Central",
  "South East",
  "South West",
  "Wales",
  "West Midlands",
];
