export const ROLE = {
  ADMIN: "Admin",
  MANAGER: "Manager",
  SITE_ACTION_MANAGER: "Site Action Manager",
  SITE_USERS: "Site Users",
  CARE_TAKER: "Caretaker",
  CONTRACTOR: "Contractor",
  SURVEYOR: "Surveyor",
  TRADESMAN: "Tradesman",
  TESTER: "Tester",
};
